import React from "react"
import { Link } from "gatsby"
import {
  Section,
  Container,
  Title,
  Subtitle,
  Content,
  Button,
  Icon,
  Column,
  Columns,
} from "bloomer"

import Layout from "../components/layout"
import SEO from "../components/seo"

const LegalPage = () => (
    <Layout>
      <SEO title="Legal" />
      <Section className="has-background-white">
        <Container>
          <Columns>
            <Column>
              {/* Page title */}
              <Title isSpaced>The Small Print</Title>
              <Subtitle>Legal stuff</Subtitle>
                {/* Go to PrivacyPolicy page button */}
                          <Link to="/privacy-policy">
                    <Button isColor="primary" className="is-rounded" id="is-spaced">
                    <Icon className="fa fa-arrow-right fa-sm" />
                    <span>Go back to the Privacy Policy</span>
                    </Button>
                </Link>
            </Column>
          </Columns>
        </Container>
      </Section>

      <Section>
            <Container>
                        {/* Built with Gatsby, Bulma, and Bloomer */}
                    <Content isSize="small">
                    <p>
                        © {new Date().getFullYear()}, Built with
                        {` `}
                        <a
                        href="https://www.gatsbyjs.org"
                        target="_blank"
                        rel="noopener noreferrer"
                        >
                        Gatsby
                        </a>
                        ,{" "}
                        <a
                        href="https://bulma.io"
                        target="_blank"
                        rel="noopener noreferrer"
                        >
                        Bulma
                        </a>{" "}
                        and{" "}
                        <a
                        href="https://bloomer.js.org"
                        target="_blank"
                        rel="noopener noreferrer"
                        >
                        Bloomer Components
                        </a>{" "}
                    </p>
                    </Content>
            </Container>

      </Section>

      <Section>
          <Container>
            {/* Back to homepage button */}
                <Link to="/">
                    <Button isColor="primary" className="is-rounded" id="is-spaced">
                    <Icon className="fa fa-arrow-left fa-sm" />
                    <span>Go back to the homepage</span>
                    </Button>
                </Link>
          </Container>
      </Section>
    </Layout>
  )
  
  export default LegalPage