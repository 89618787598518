import React from "react"
import { Link } from "gatsby"
import {
  Section,
  Container,
  Title,
  Subtitle,
  Button,
  Icon,
  Column,
  Columns,
} from "bloomer"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicyPage = () => (
    <Layout>
      <SEO title="Privacy Policy" />
      <Section className="has-background-white">
        <Container>
          <Columns>
            <Column>
              {/* Page title */}
              <Title isSpaced>Privacy Policy</Title>
              <Subtitle>You kno we have to</Subtitle>
            </Column>
          </Columns>
        </Container>
      </Section>

      <section>
          <Container>
            {/* Back to homepage button */}
                <Link to="/">
                    <Button isColor="primary" className="is-rounded" id="is-spaced">
                    <Icon className="fa fa-arrow-left fa-sm" />
                    <span>Go back to the homepage</span>
                    </Button>
                </Link>
          </Container>
      </section>
    </Layout>
  )
  
  export default PrivacyPolicyPage