import React, { Component } from "react"
import { Link } from "gatsby"
import {
  Hero,
  HeroBody,
  Container,
  Title,
  Button,
  Columns,
  Column,
  Icon,
} from "bloomer"

export default class hero extends Component {
  render() {
    return (
      <Hero isColor="primary" isSize="medium" textalign="left">
        <HeroBody>
          <Container>
            <Columns>
              <Column isSize="1/2">
                {/* Headline */}
                <Title>{this.props.description}</Title>
                
                {/* Call to action button */}
                <p>
                <Link to="/courses/">
                  <Button
                    isColor="white"
                    isOutlined
                    className="is-rounded"
                    id="btn-spaced"
                  >
                    <span>Get started Now</span>
                    <Icon className="fa fa-arrow-right fa-sm" />
                  </Button>
                </Link>
              </p>
              </Column>
            </Columns>
          </Container>
        </HeroBody>
      </Hero>
    )
  }
}
