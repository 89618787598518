import React from "react"
import { Link } from "gatsby"
import {
  Section,
  Container,
  Box,
  Title,
  Subtitle,
  Button,
  Icon,
  Column,
  Columns,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardHeader,
  ModalCardTitle,
  Delete,
  ModalCardBody,
  ModalCardFooter,
  Notification,
  Card,
  CardHeader,
  CardHeaderTitle,
  CardHeaderIcon,
  CardImage,
  Image,
  CardContent,
  Content,
  Pagination,
  PageControl,
} from "bloomer"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TestPage = () => (
  <Layout>
    <SEO title="Courses" />
    <Section className="has-background-white">
      <Container>
        <Columns>
          <Column>
            {/* Page title */}
            <Title isSpaced>Courses</Title>
            <Subtitle>This is our list of courses.</Subtitle>
            {/* Back to homepage button */}
            <Link to="/">
              <Button isColor="primary" className="is-rounded" id="is-spaced">
                <Icon className="fa fa-arrow-left fa-sm" />
                <span>Go back to the homepage</span>
              </Button>
            </Link>
          </Column>
        </Columns>
      </Container>
    </Section>
  <Container>
  <Box>A white box to contain other elements</Box>
  </Container>
<Section className="has-background-white">
  <Container>
      <Button isColor="success" className="is-rounded" id="is-spaced" >Click Me for a Modal experience</Button>
  <Modal>
    <ModalBackground />
    <ModalCard>
        <ModalCardHeader>
            <ModalCardTitle>ModalCard Title</ModalCardTitle>
            <Delete />
        </ModalCardHeader>
        <ModalCardBody>
            {/* Your Content  */}
            Here comes some content
        </ModalCardBody>
        <ModalCardFooter>
            <Button isColor="success">Save</Button>
            <Button isColor="warning">Cancel</Button>
        </ModalCardFooter>
    </ModalCard>
</Modal>
  </Container>
</Section>
<Section>
  <Container>
    <Columns isCentered>
      <Column isSize='1/3'><Notification isColor="success" hasTextAlign="centered"> Some Text in First column </Notification>
      </Column>
      <Column isSize='1/3'><Notification isColor="warning" hasTextAlign="centered"> Some Text in second column </Notification>
      </Column>
      <Column isSize='1/3'><Notification isColor="primary" hasTextAlign="centered"> Some Text in third and final column </Notification>
      </Column>
    </Columns>
  </Container>
</Section>

<Section>
  <Container>
    <Columns isCentered>
      <Column isSize='1/3'>
      <Card>
    <CardHeader>
        <CardHeaderTitle>
        <strong>Back2basics</strong>
        </CardHeaderTitle>
        <CardHeaderIcon>
            <Icon className="fa fa-angle-down" />
        </CardHeaderIcon>
    </CardHeader>
    <CardImage>
        <Link to="https://azybao.training/courses/b2b-project-management/">
        <Image isRatio='16:9' src='https://cdn1.azybao.training/wp-content/uploads/2019/12/16211538/Cover-PM-in5days-9-400x258.png' /></Link>
    </CardImage>
    <CardContent>

        <Content>
        <strong>Project management</strong>
<p>All you need to know to quickly start with your new project.</p>
            <br/>
            <small>Online course - Own pace</small>
            <br/>
            <Link to="https://azybao.training/courses/b2b-project-management/">
            <Button isColor="primary" className="is-rounded" id="is-spaced" >Get Started</Button></Link>
        </Content>
    </CardContent>
</Card>
      </Column>
  <Column isSize='1/3'>
      <Card>
    <CardHeader>
        <CardHeaderTitle>
        <strong>Back2basics</strong>
        </CardHeaderTitle>
        <CardHeaderIcon>
            <Icon className="fa fa-angle-down" />
        </CardHeaderIcon>
    </CardHeader>
    <CardImage>
        <Link to="https://azybao.training/courses/back2basics-meetings-management/">
        <Image isRatio='16:9' src='https://azybao.training/wp-content/uploads/CourseCover-Meetings-Back2Basics-4site.jpg' /></Link>
    </CardImage>
    <CardContent>

        <Content>
        <strong>Managing Meetings</strong>
<p>Waste no time in useless meetings by applying these basic tricks.</p>
            <br/>
            <small>Online course - Own pace</small>
            <br/>
            <Link to="https://azybao.training/courses/back2basics-meetings-management/">
            <Button isColor="primary" className="is-rounded" id="is-spaced" >Get Started</Button></Link>
        </Content>
    </CardContent>
</Card>
      </Column>
      <Column isSize='1/3'>
  <Card>
    <CardHeader>
        <CardHeaderTitle>
        <strong>Back2basics</strong>
        </CardHeaderTitle>
        <CardHeaderIcon>
            <Icon className="fa fa-angle-down" />
        </CardHeaderIcon>
    </CardHeader>
    <CardImage>
        <Link to="https://azybao.training/courses/back2basics-risk-management/">
        <Image isRatio='16:9' src='https://azybao.training/wp-content/uploads/CourseCover-Risks-Back2Basics-4site.jpg' /></Link>
    </CardImage>
    <CardContent>

        <Content>
        <strong>Risk management</strong>
<p>Never be surprised by stuff happening in your project by being prepared.</p>
            <br/>
            <small>Online course - Own pace</small>
            <br/>
            <Link to="https://azybao.training/courses/back2basics-risk-management/">
            <Button isColor="primary" className="is-rounded" id="is-spaced" >Get Started</Button></Link>
        </Content>
    </CardContent>
</Card>      </Column>
    </Columns>
  </Container>
</Section>

<Section>
  <Container>
  
  </Container>
</Section>

<Section>
  <Container>
      <Pagination>
      <Link to="/page-2/">
        <PageControl>Previous</PageControl>
        </Link>
        <Link to="/join/">
        <PageControl isNext>Next</PageControl>
        </Link>
      </Pagination>
  </Container>
</Section>

  </Layout>
)

export default TestPage
