import React from "react"
import BackgroundImage from "gatsby-background-image"
import { graphql } from "gatsby"

import { Link } from "gatsby"
import {
  Section,
  Container,
  Content,
  Title,
  Button,
  Icon,
  Column,
} from "bloomer"

import Layout from "../components/layout"
import SEO from "../components/seo"

const HeroPage = (props) => (
  <Layout>
    <SEO title="Home" />
    <BackgroundImage
      className="masthead"
      fluid={props.data.indexImage.childImageSharp.fluid}
      fadeIn
    >
      <div className="black-overlay">
        <div className="content-box">
          <h1 isSize="Large">Apply Beyond Methods</h1>
          <h2>Enable Change through Projects</h2>
          <Title isSpaced isSize={1} hasTextColor="white">Courses</Title>
                          {/* Call to action button */}
                          <p>
                <Link to="/courses/">
                  <Button
                    isColor="white"
                    isOutlined
                    className="is-rounded"
                    id="btn-spaced"
                  >
                    <span>Get started Now</span>
                    <Icon className="fa fa-arrow-right fa-sm" />
                  </Button>
                </Link>
              </p>
        </div>
      </div>
    </BackgroundImage>

    <Section>
        <Container>
          {/* Intro section  */}
          <Column isSize="2/3">
            <Title>Apply Beyond Methods</Title>
            <Content>
              <p>
                Enable Change Through Projects{" "}
                <strong>Beyond PRINCE2 and MSP</strong> to gather improved and faster results.
<br />
                More than Best Practice, what works <strong>based on real life experience</strong>{" "}
                needed to get your project started.
              </p>
              {/* List of features */}
              <ul id="list-unstyled">
                <li>
                <Icon
                    className="fa fa-bullseye fa-lg has-text-primary"
                    id="feature-icon"
                  />
                  Project Management
                </li>
                <li>
                  <Icon
                    className="fa fa-cogs fa-lg has-text-primary"
                    id="feature-icon"
                  />
                  Enabling and Managing Change
                </li>
                <li>
                  <Icon
                    className="fa fa-exclamation-triangle fa-lg has-text-primary"
                    id="feature-icon"
                  />
                  Being prepared for Risks
                </li>
                <li>
                  <Icon
                    className="fa fa-users fa-lg has-text-primary"
                    id="feature-icon"
                  />
                  Communication and getting the most of meetings
                </li>
              </ul>
              {/* Navigate to page 2 */}
              <p>
                <Link to="/courses/">
                  <Button
                    isColor="primary"
                    className="is-rounded"
                    id="btn-spaced"
                  >
                    <span>Go for your next course</span>
                    <Icon className="fa fa-arrow-right fa-sm" />
                  </Button>
                </Link>
              </p>
            </Content>
          </Column>

          {/* Features section begins */}
        </Container>
      </Section>

  </Layout>
)

export default HeroPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    indexImage: file(relativePath: { eq: "1-man-talking-telephone-lifestyle-concept-4site.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
