import React from "react"
import BackgroundImage from "gatsby-background-image"
import { graphql } from "gatsby"

import { Link } from "gatsby"
import {
  Title,
  Button,
  Icon,
} from "bloomer"

import Layout from "../components/layout"
import SEO from "../components/seo"

const HeroPage = (props) => (
  <Layout>
    <SEO title="HeroBG" />
    <BackgroundImage
      className="masthead"
      fluid={props.data.indexImage.childImageSharp.fluid}
      fadeIn
    >
      <div className="black-overlay">
        <div className="content-box">
          <h1 isSize="Large">Apply Beyond Methods</h1>
          <h2>Enable Change through Projects</h2>
          <Title isSpaced isSize={1} hasTextColor="white">Courses</Title>
                          {/* Call to action button */}
                          <p>
                <Link to="/courses/">
                  <Button
                    isColor="white"
                    isOutlined
                    className="is-rounded"
                    id="btn-spaced"
                  >
                    <span>Get started Now</span>
                    <Icon className="fa fa-arrow-right fa-sm" />
                  </Button>
                </Link>
              </p>
        </div>
      </div>
    </BackgroundImage>
  </Layout>
)

export default HeroPage

export const pageQuery = graphql`
  query {
    indexImage: file(relativePath: { eq: "1-man-talking-telephone-lifestyle-concept-4site.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;